module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/.github/.github/pwa/src/layout/Layout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"exclude":["**/dev-404-page/**","**/404/**","**/404.html","**/offline-plugin-app-shell-fallback/**"],"excludeOptions":{"separator":"."},"autoGenHomeLabel":"OpenCatalogi","crumbLabelUpdates":[{"pathname":"/components","crumbLabel":"Components overview"},{"pathname":"/components/[componentId]","crumbLabel":"Component"},{"pathname":"/organizations/[organizationId]","crumbLabel":"Organization"},{"pathname":"/categories/[categoryId]","crumbLabel":"Category"},{"pathname":"/documentation/about","crumbLabel":"Over OpenCatalogi"},{"pathname":"/applications/[applicationId]","crumbLabel":"Application"},{"pathname":"/github/[md]","crumbLabel":"Markdown file"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
